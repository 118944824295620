import styled from "styled-components";
import colors from "../colors";

const H3 = styled.h3`
  font-size: 1.5em;
  line-height: 1.6;
  text-align: ${props => (props.left ? "left" : "center")};
  align-self: ${props => (props.left ? "flex-start" : "auto")};
  color: ${props => (props.dark ? colors.ultra_dark : props.light ? colors.grey : colors.dark)};
  font-weight: 500;
  margin: 0.1em 0 0.5em;
  max-width: ${props => (props.small ? "14em" : "30em")};
  @media (max-width: 640px) {
    font-size: 1.4em;
  }
`;

export default H3;
