import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import NewsletterBigCard from "../components/cards/newsletterBigCard";
import NewslettersNavigation from "../components/newslettersNavigation";
import { Router } from "@reach/router";
import H2 from "../components/styled/H2";
import H3 from "../components/styled/H3";
import Axios from "axios";
import P1 from "../components/styled/P1";

const LatestIssueWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid rgba(226, 226, 229, 0.6);
  box-shadow: ${props => (props.dark ? "0px 4px 100px -10px rgba(147, 160, 187, 0.6)" : "0px 4px 20px -5px rgba(147, 160, 187, 0.15)")};
  border-radius: 10px;
  zoom: 1.1;
  word-break: break-word;
`;

const LoadingIssueWrapper = ({ msg = "Loading your issue..." }) => (
  <LatestIssueWrapper>
    <P1 centered style={{ padding: "1em" }}>
      {msg}
    </P1>
  </LatestIssueWrapper>
);

const MiniSite = props => {
  const { newsletter } = props.pageContext;

  return (
    <Router>
      <NewsletterIssue path={`/n/${newsletter.slug}/`} newsletter={newsletter} />
      <NewsletterIssue path={`/n/${newsletter.slug}/:serialNumber`} newsletter={newsletter} />
    </Router>
  );
};

const NewsletterIssue = props => {
  const { serialNumber, newsletter } = props;
  const { slug, oldIssuesCount: issues, title, topic } = newsletter;

  // if no serial number provided, get latest issue instead
  let issueSerialNumber = serialNumber || issues;

  const clampedIssue = Math.max(Math.min(issueSerialNumber, issues), 1);
  const url = `https://unreadit-archive.s3.us-east-1.amazonaws.com/${slug}/${clampedIssue}.html`;

  const [loading, setLoading] = React.useState(false);
  const [issueHTML, setIssueHTML] = React.useState(null);

  React.useEffect(() => {
    // only show loading if it takes longer than xxx ms to load the issue from S3
    const timeout = setTimeout(() => setLoading(true), 1000);
    Axios.get(url).then(res => {
      setIssueHTML(res.data);
      window.clearTimeout(timeout);
      setLoading(false);
    });
  }, [url]);

  // Since each issue does not have its own page (we rely on dynamic routing)
  // we can't have issue-specific meta tags for properties like the page title.
  // For this reason, we fallback to a more generic title when we don't have the issue number
  // (e.g. when the page is loaded without running JS, e.g. by Twitter for showing the card).
  const seoTitle = serialNumber
    ? `Unreadit / ${title} #${clampedIssue}`
    : `Unreadit / ${title} - Newsletter with top ${title.toLowerCase()} posts`;

  return (
    <Layout darkbg>
      <SEO
        title={seoTitle}
        description={topic}
        keywords={["unreadit", title.toLowerCase(), "reddit", "newsletter"]}
        card={slug}
        icon={slug}
      />
      <Section darkbg centered>
        <NewsletterBigCard newsletter={newsletter} />
      </Section>
      <Section centered darkbg nomargin>
        <H2>{`Old Archive`}</H2>
        <P1 style={{ paddingBottom: "2.5rem" }}>
          Unreadit is now powered by <a href="https://mailbrew.com/?utm_campaign=unreadit">Mailbrew</a>. Here are the previous issues before
          the transition.
        </P1>
        <H3>{`Issue #${clampedIssue}`}</H3>
        <NewslettersNavigation slug={slug} issue={clampedIssue} latest={clampedIssue == issues} />
        {loading ? <LoadingIssueWrapper /> : <LatestIssueWrapper dangerouslySetInnerHTML={{ __html: issueHTML }} />}
      </Section>
    </Layout>
  );
};

export default MiniSite;
