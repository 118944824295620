import React from "react";
import styled from "styled-components";
import colors from "../colors";

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 0.5em 0.8em;
  font-size: 1.4em;
  color: ${colors.dark};
  font-weight: 500;
  border-radius: 0.3em;
  border: none;
  background: ${colors.ultra_light};
  margin-top: ${props => (props.nomargin ? "0em" : "0.8em")};
  margin-bottom: 1em;
  outline: none;
  min-height: 0;
  height: "6em";
  overflow: "auto";
  ::placeholder {
    color: ${colors.grey};
  }
`;

const StyledTextInput = styled.input`
  width: 100%;
  resize: none;
  padding: 0.5em 0.8em;
  font-size: 1.4em;
  color: ${colors.dark};
  font-weight: 500;
  border-radius: 0.3em;
  border: 1px solid ${colors.light};
  background: ${colors.ultra_light};
  margin-top: ${props => (props.nomargin ? "0em" : "0.8em")};
  margin-bottom: 1em;
  outline: none;
  min-height: 0;
  height: "2.4em";
  overflow: "hidden";
  ::placeholder {
    color: ${colors.grey};
  }
`;

const TextInput = props => {
  const { big } = props;

  if (big) {
    return <StyledTextArea wrap="on" rows="auto" {...props} />;
  } else {
    return <StyledTextInput {...props} />;
  }
};

export default TextInput;
