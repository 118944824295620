import styled from "styled-components";
import colors from "../colors";

const P2 = styled.p`
  font-size: 1.1em;
  text-align: ${props => (props.centered ? "center" : "left")};
  color: ${props => (props.accent ? colors.accent : props.dark ? colors.ultra_dark : colors.dark)};
  margin: 0.1em 0 0.2em;
  ${props => props.noMargin && "margin: 0;"}
`;

export default P2;
