import React from "react";
import styled from "styled-components";
import P2 from "./styled/P2";
import svgSprites from "./svgSprites";
import colors from "./colors";

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.3em 0.6em;
  border-radius: 0.3em;
  border: 1px solid ${colors.lighter};
  text-decoration: none;
  ${props => props.big && "font-size: 1.25em;"}
  p {
    margin: 0;
    position: relative;
    bottom: -0.05em;
    color: #f75858;
    font-size: 0.9em;
  }
  svg {
    margin-right: 0.2em;
    width: 1.2em;
    height: 1.2em;
  }
  :hover {
    border: 1px solid hsla(360, 91%, 66%, 0.5);
  }
`;

const PoweredByMailbrew = props => {
  return (
    <Wrapper big={props.big} href="https://mailbrew.com/?ref=unreadit-website" target="_blank">
      {svgSprites.logo.mailbrew}
      <P2>Created with Mailbrew</P2>
    </Wrapper>
  );
};

export default PoweredByMailbrew;
