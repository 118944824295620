import styled from "styled-components";
import colors from "../colors";

const H1 = styled.h1`
  font-size: 2.5em;
  font-weight: 600;
  margin: 0.2em 0 0.3em;
  text-align: ${props => (props.left ? "left" : "center")};
  color: ${colors.ultra_dark};
  width: 100%;
  span {
    background: ${colors.accent};
    color: ${colors.lighter};
    padding: 0.05em 0.3em;
  }
`;

export default H1;
