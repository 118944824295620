import React, { useState, useEffect } from "react";
import styled from "styled-components";
import H4 from "./styled/H4";
import TextInput from "./styled/TextInput";
import Button from "./button";
import validateEmail from "../../utils/validateEmail";
import P2 from "./styled/P2";
import http from "../utils/http";
import track from "../../utils/trackEvent";
import svgSprites from "./svgSprites";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  > div:last-child {
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 0.4rem;
  }
  * {
    text-align: left;
  }
`;

const FormError = styled.div`
  :not(:empty) {
    color: ${props => (props.warning ? "#ff5959" : "#43bc56")};
    margin-bottom: 0.8em;
  }
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0.8em;
`;

const Toggle = styled.div`
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const NewsletterSubscribeForm = props => {
  const { brew_url, slug, formOnScreen } = props;
  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState({ text: "", warning: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem("user_email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [formOnScreen]);

  useEffect(() => {
    localStorage.setItem("user_email", email);
  }, [email]);

  useEffect(() => {
    setFormMessage({ text: "", warning: false });
  }, [formOnScreen]);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  if (!brew_url) return null;

  const shareUrlRegex = /unreadit\/.+-(.+)/;

  const shareId = brew_url.match(shareUrlRegex)[1];

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setFormMessage({ text: "You need to insert a valid email.", warning: true });
      setLoading(false);
      return;
    }

    http
      .post(`https://api.mailbrew.com/brew_public_subscribe/`, { email: email, share_id: shareId })
      .then(() => {
        track.goal("Subscribed to Newsletter");
        track.goal(`Subscribed to ${slug}`);
        setFormMessage({
          text: `Subscribed! Please check your email and click on the confirmation link.`,
          warning: false
        });
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        track.goal("Subscribe error");
        setLoading(false);
        if (error.response) {
          error.response
            .json()
            .then(serverErrors => {
              setFormMessage({ text: serverErrors.detail, warning: true });
            })
            .catch(() => {
              setFormMessage({ text: "Sorry, there seems to be a problem.", warning: true });
            });
        } else {
          setFormMessage({ text: "Sorry, there seems to be a problem.", warning: true });
        }
      });
  };

  return (
    <FormWrapper>
      <TextInput
        placeholder="Your email address"
        value={email}
        onChange={handleChange}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <FormError warning={formMessage.warning}>{formMessage.text}</FormError>
      <div>
        <Button loading={loading} label="Subscribe" onClick={handleSubmit} />
      </div>
    </FormWrapper>
  );
};

export default NewsletterSubscribeForm;
