import moment from "moment";

function prefers12HoursFormat() {
  const formattedDate = new Date().toLocaleString().toLowerCase();
  return formattedDate.includes("am") || formattedDate.includes("pm");
}

const SCHEDULES_VERSION = 1;

const makeDailySchedule = times => ({
  version: SCHEDULES_VERSION,
  type: "daily",
  times
});

const makeWeeklySchedule = (weekdays, times) => ({
  version: SCHEDULES_VERSION,
  type: "weekly",
  weekdays,
  times
});

const makeMonthlySchedule = (day_of_month, times) => ({
  version: SCHEDULES_VERSION,
  type: "monthly",
  day_of_month,
  times
});

const makeTime = (hour, minute) => ({
  hour,
  minute
});

const MO = 0;
const TU = 1;
const WE = 2;
const TH = 3;
const FR = 4;
const SA = 5;
const SU = 6;

const allWeekdaysNumbers = [MO, TU, WE, TH, FR, SA, SU];
const allWeekdaysSingleLetter = ["M", "T", "W", "T", "F", "S", "S"];
const allWeekdaysDoubleLetter = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const allWeekdaysLong = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

function migrateSchedule(schedule) {
  // version 0 -> 1
  if (!schedule.version) {
    switch (schedule.type) {
      case "daily":
        return makeDailySchedule([makeTime(schedule.hour, schedule.minute)]);
      case "weekly":
        return makeWeeklySchedule(
          [allWeekdaysLong.indexOf(schedule.day_of_week)],
          [makeTime(schedule.hour, schedule.minute)]
        );
      case "monthly":
        return makeMonthlySchedule(schedule.day_of_month, [makeTime(schedule.hour, schedule.minute)]);
      default:
        return makeDailySchedule([makeTime(9, 0)]);
    }
  }

  return schedule;
}

function capitalize(string) {
  if (!string || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatDailySchedule(schedule) {
  return formatTimes(schedule.times);
}

function formatWeeklySchedule(schedule) {
  const weekdays = schedule.weekdays;

  if (weekdays.length === 1) {
    const weekdayLong = allWeekdaysLong[weekdays[0]];
    return `${capitalize(weekdayLong)} • ${formatTimes(schedule.times)}`;
  } else {
    const letterWeekdays = weekdays
      .slice() // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
      .sort()
      .map(weekdayNum => allWeekdaysDoubleLetter[weekdayNum])
      .join(", ");
    return `${letterWeekdays} • ${formatTimes(schedule.times)}`;
  }
}

function formatMonthlySchedule(schedule) {
  const { day_of_month } = schedule;
  return `${formatOrdinal(day_of_month)} at ${formatTimes(schedule.times)}`;
}

function formatTimes(times) {
  if (!times) return "";

  const formattedTimes = times.map(time => {
    const m = moment()
      .hour(time.hour)
      .minute(time.minute);

    if (prefers12HoursFormat()) {
      return m.format("h:mm a");
    } else {
      return m.format("H:mm");
    }
  });

  return formattedTimes.join(", ");
}

function formatOrdinal(number) {
  // https://en.wikipedia.org/wiki/English_numerals#Ordinal_numbers

  const leastSignificantDigit = number % 10;
  const secondLeastSignificantDigit = Math.floor(number / 10) % 10;

  if (secondLeastSignificantDigit === 1) {
    return `${number}th`;
  }

  let suffix;
  // prettier-ignore
  switch (leastSignificantDigit) {
    case 1: suffix = "st"; break;
    case 2: suffix = "nd"; break;
    case 3: suffix = "rd"; break;
    default: suffix = "th"; break;
  }
  return `${number}${suffix}`;
}

export default function humanFriendlySchedule(schedule) {
  switch (schedule.type) {
    case "daily": {
      if (schedule.exclude_weekends) {
        return `Mon - Fri at ${formatDailySchedule(schedule)}`;
      } else {
        return `Daily at ${formatDailySchedule(schedule)}`;
      }
    }
    case "weekly": {
      return formatWeeklySchedule(schedule);
    }
    case "monthly": {
      return `${formatOrdinal(schedule.day_of_month)} of each month at ${formatTimes(schedule.times)}`;
    }

    default:
      return "unknown schedule";
  }
}
