import React from "react";
import styled from "styled-components";
import H4 from "./styled/H4";
import { Link } from "gatsby";
import svgSprites from "./svgSprites";

const NewslettersNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  width: 100%;
`;

const OtherNewsletterButton = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.8em;
  align-items: center;
  svg {
    width: 9px;
    height: 20px;
    margin-bottom: 0.3em;
  }
`;

const NewslettersNavigation = props => {
  const { slug, issue, latest = false } = props;

  return (
    <NewslettersNavigationWrapper>
      {issue > 1 ? (
        <Link style={{ textDecoration: "none" }} to={`/n/${slug}/${parseInt(issue) - 1}`}>
          <OtherNewsletterButton>
            {svgSprites.glyphs.prev}
            <H4>Previous</H4>
          </OtherNewsletterButton>
        </Link>
      ) : (
        <div />
      )}
      {!latest ? (
        <Link style={{ textDecoration: "none" }} to={`/n/${slug}/${parseInt(issue) + 1}`}>
          <OtherNewsletterButton>
            <H4>Next</H4>
            {svgSprites.glyphs.next}
          </OtherNewsletterButton>
        </Link>
      ) : (
        <div />
      )}
    </NewslettersNavigationWrapper>
  );
};

export default NewslettersNavigation;
