/* globals ga */

const track = {
  goal: function(name, value) {
    if (typeof ga !== "undefined" && ga) {
      if (value) {
        ga("send", {
          hitType: "event",
          eventCategory: "Event",
          eventAction: name,
          eventValue: value
        });
        ga.goal(name, value);
      } else {
        ga("send", {
          hitType: "event",
          eventCategory: "Event",
          eventAction: name
        });
      }
    }
  }
};

export default track;
