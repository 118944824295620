/**
 * Wrapper around window.fetch that sets the content type to json and
 *  fails automatically if the status code is not in the [200, 399] range.
 */
function post(url, body) {
  return new Promise((resolve, reject) => {
    return fetch(url, {
      method: "POST",
      headers: {
        // json content type and accepted repsonse
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body || {})
    })
      .then(response => {
        // fail if status code does not fall within range
        if (200 <= response.status && response.status <= 399) {
          resolve(response);
        } else {
          reject({
            message: "Got unexpected status code",
            response
          });
        }
      })
      .catch(error => {
        reject({
          message: "Network error",
          error
        });
      });
  });
}

function get(url) {
  return new Promise((resolve, reject) => {
    return fetch(url, { method: "GET" })
      .then(response => {
        if (200 <= response.status && response.status <= 399) {
          resolve(response);
        } else {
          reject({
            message: "Got unexpected status code",
            response
          });
        }
      })
      .catch(error => {
        reject({
          message: "Network error",
          error
        });
      });
  });
}

export default { get, post };
