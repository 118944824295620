import styled from "styled-components";

const CardWrapper = styled.div`
  /* min-height: ${props => (props.mini ? "auto" : "26em")}; */
  max-width: ${props => (props.mini ? "36em !important" : "auto")};
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(226, 226, 229, 0.6);
  border-radius: 10px;
  box-shadow: ${props =>
    props.dark ? "0px 4px 100px -10px rgba(147, 160, 187, 0.6)" : "0px 4px 20px -5px rgba(147, 160, 187, 0.15)"};
  padding: 1.5em 2em 1.8em;
  @media (max-width: 640px) {
    padding-right: 1.2em;
    padding-left: 1.2em;
  }
  overflow: hidden;
  position: relative;
  z-index: 1;

  ${props =>
    props.columns &&
    `
    display: inline-grid;
    grid-template-columns: ${props.bigColumn ? "30em" : "20em"} auto;
    @media (max-width: 890px) {
      display: block;
      grid-template-columns: 1fr;
    }
  `}

  ${props =>
    props.floating &&
    `
    border: none;
    @media (max-width: 640px) {
      border-radius: 0;
      min-height: 100vh;
      -webkit-overflow-scrolling: touch;
    }
  `}
`;

export default CardWrapper;
