import React from "react";
import styled from "styled-components";
import linuz90 from "../images/editors/linuz90.jpg";
import frankdilo from "../images/editors/frankdilo.jpg";
import P2 from "./styled/P2";
import colors from "./colors";

const editors = {
  linuz90: {
    name: "Fabrizio Rinaldi",
    link: "https://fabriziorinaldi.com",
    avatar: linuz90
  },
  frankdilo: {
    name: "Francesco Di Lorenzo",
    link: "https://twitter.com/frankdilo",
    avatar: frankdilo
  }
};

const avatarSize = "1.4em";

const Editor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.6em 0 0.9em;
  img {
    margin: 0 0.1em 0 0.5em;
    width: ${avatarSize};
    height: ${avatarSize};
    border-radius: ${avatarSize};
  }
`;

const NewsletterEditor = props => {
  const { editor } = props;

  return (
    <Editor>
      <P2 style={{ color: colors.ultra_dark }}>Edited by</P2>
      <img src={editors[editor].avatar} alt={editors[editor].name} />
      <a href={editors[editor].link}>
        <P2 accent style={{ lineHeight: 1, margin: "0 0 0 .3em", color: colors.ultra_dark }}>
          {editors[editor].name}
        </P2>
      </a>
    </Editor>
  );
};

export default NewsletterEditor;
