import React from "react";
import styled from "styled-components";
import H1 from "../styled/H1";
import colors from "../colors";
import P1 from "../styled/P1";
import P2 from "../styled/P2";
import NewsletterSubscribeForm from "../newsletterSubscribeForm";
import GlyphCard from "./GlyphCard";
import kFormatter from "../../../utils/kFormatter";
import { Link } from "gatsby";
import H4 from "../styled/H4";
import NewsletterEditor from "../newsletterEditor";
import PoweredByMailbrew from "../PoweredByMailbrew";
import humanFriendlySchedule from "../../utils/formatSchedule";

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  * {
    text-align: left;
  }
`;

const SubredditsWrapper = styled.div`
  text-align: left;
  color: ${colors.grey};
  margin: 0.8em 0 0;
  font-size: 1.1em;
  span {
    display: inline-block;
    padding: 0.2em 0.6em 0.2em 0;
  }
`;

const FormWrapper = styled.div`
  padding-left: 1.8em;
  margin-left: 1.6em;
  border-left: 1px solid ${colors.lighter};
  /* min-height: 100%; */
  display: flex;
  flex-direction: column;

  @media (max-width: 890px) {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
    margin-top: 0;
  }
`;

const NewsletterBigCard = props => {
  const { title, slug, brew_url, mailbrew_data, closeClicked } = props.newsletter || {};

  const friendlySchedule = humanFriendlySchedule(mailbrew_data.schedule);

  return (
    <GlyphCard image={slug} closeClicked={closeClicked} {...props} columns>
      <Details>
        <div>
          <Link style={{ textDecoration: "none" }} to={`/n/${slug}`}>
            <H1 style={{ marginTop: "0", marginBottom: ".3em" }}>{title}</H1>
          </Link>
        </div>
      </Details>
      <FormWrapper>
        <P1 style={{ color: colors.ultra_dark }}>{mailbrew_data.description}</P1>
        <P2 style={{ paddingBottom: "0.8rem" }}>
          <a href={brew_url} target="_blank">
            Past Issues
          </a>
          {" • "}
          <Link to={`/n/${slug}`}>old archive</Link>
        </P2>
        <P2 style={{ color: colors.dark }}>
          {friendlySchedule} (PT) • {mailbrew_data.subscribers_count > 100 && `${kFormatter(mailbrew_data.subscribers_count)} Subscribers`}
        </P2>
        <NewsletterSubscribeForm topic={mailbrew_data.description} brew_url={brew_url} slug={slug} formOnScreen={props.formOnScreen} />
      </FormWrapper>
    </GlyphCard>
  );
};

export default NewsletterBigCard;
