import React from "react";
import styled from "styled-components";
import svgSprites from "../svgSprites";
import CardWrapper from "./CardWrapper";

const SvgBg = styled.div`
  display: block;
  position: absolute;
  top: -6em;
  left: -6em;
  width: 28em;
  height: 28em;
  /* pointer-events: none; */
  z-index: -1;
  svg {
    opacity: 0.08;
    width: 100%;
    height: 100%;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  @media (min-width: 640px) {
    display: none;
  }
`;

const GlyphCard = props => {
  const { closeClicked, style } = props;

  return (
    <CardWrapper {...props} style={style}>
      <SvgBg>{svgSprites.newsletters[props.image]}</SvgBg>
      {props.floating && <CloseBtn onClick={closeClicked}>{svgSprites.glyphs.close}</CloseBtn>}
      {props.children}
    </CardWrapper>
  );
};

export default GlyphCard;
